<template>
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'edit'" class="justify-center text-h5" />

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="12">
              <v-text-field
                v-model="jobDataLocal.title"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('title')"
                :placeholder="$t('title')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="12">
              <v-textarea
                v-model="jobDataLocal.description"
                outlined
                dense
                maxlength="6000"
                hide-details="auto"
                :label="$t('description')"
                :placeholder="$t('description')"
                rows="4"
                :rules="[validators.required]"
              ></v-textarea>
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="6">
              <v-text-field
                v-model="jobDataLocal.city"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('city')"
                :placeholder="$t('city')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="6">
              <v-text-field
                v-model="jobDataLocal.country"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('country')"
                :placeholder="$t('country')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB_STAGE, props.jobData)" cols="12">
              <v-select
                v-model="jobDataLocal.stage"
                outlined
                dense
                :items="getStageItems"
                :label="$t('status', { postfix: '' })"
                hide-details="auto"
              />
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished'
              "
              cols="12"
            >
              <v-switch
                v-model="sendEmail"
                :label="$t('notifyCandidateByEmail')"
                hide-details="auto"
                class="mt-0"
                inset
              />
            </v-col>
            <v-col
              v-if="props.jobData.stage !== jobDataLocal.stage && jobDataLocal.stage === 'finished' && sendEmail"
              cols="12"
            >
              <v-divider />
            </v-col>
            <v-col
              v-if="props.jobData.stage !== jobDataLocal.stage && jobDataLocal.stage === 'finished' && sendEmail"
              cols="12"
            >
              <v-alert color="warning" text class="mb-0">
                {{ $t('moveToFinished') }}
              </v-alert>
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished' &&
                sendEmail
              "
              cols="12"
            >
              <v-select
                v-model="selectedEmail"
                :label="$t('selectEmail')"
                :placeholder="$t('selectEmail')"
                :items="emailTemplates"
                outlined
                dense
                hide-details="auto"
                @change="setEmailTemplateData"
              ></v-select>
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished' &&
                sendEmail
              "
              cols="12"
              sm="7"
            >
              <v-text-field
                v-model="cc"
                :label="$t('cc')"
                :placeholder="$t('cc')"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :hint="$t('separateByComma')"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished' &&
                sendEmail
              "
              cols="12"
              sm="5"
            >
              <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary" hide-details="auto">
                <template v-slot:label> {{ $t('sendMeCopy') }} </template>
              </v-switch>
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished' &&
                sendEmail
              "
              cols="12"
            >
              <v-select
                v-model="selectedRejectReason"
                :label="$t('selectRejectReason')"
                :placeholder="$t('selectRejectReason')"
                :items="rejectReasonChoices"
                item-value="id"
                item-text="rejectReason"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished' &&
                sendEmail
              "
              cols="12"
              sm="7"
            >
              <v-text-field
                v-model="selectedEmailTemplateSubject"
                :label="$t('subject')"
                :placeholder="$t('subject')"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished' &&
                sendEmail
              "
              cols="12"
              sm="5"
            >
              <v-select
                v-model="selectedEmailTemplateLang"
                outlined
                dense
                :items="[
                  {
                    text: $t('pl'),
                    value: 'pl',
                  },
                  {
                    text: $t('eng'),
                    value: 'eng',
                  },
                ]"
                :label="$t('language')"
                :placeholder="$t('language')"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col
              v-if="
                $can(ACTIONS.JOBS.MANAGE_JOB, props.jobData) &&
                props.jobData.stage !== jobDataLocal.stage &&
                jobDataLocal.stage === 'finished' &&
                sendEmail
              "
              cols="12"
            >
              <summer-note :config="{ height: 300 }" hide-link-and-image-button @ready="readySummerNote"></summer-note>
            </v-col>
            <v-col
              v-if="props.jobData.stage !== jobDataLocal.stage && jobDataLocal.stage === 'finished' && sendEmail"
              cols="12"
            >
              <v-divider />
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="12">
              <v-autocomplete
                v-model="jobDataLocal.assignedTo"
                outlined
                dense
                :items="assignedToItems"
                :label="$t('assignedTo')"
                :placeholder="$t('assignedTo')"
                hide-details="auto"
                :rules="[validators.required]"
                @change="reArrangeSharedWithItems"
              />
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="12">
              <v-autocomplete
                v-model="jobDataLocal.sharedWith"
                outlined
                dense
                multiple
                :items="sharedWithItems"
                :label="$t('sharedWith')"
                :placeholder="$t('sharedWith')"
                hide-details="auto"
              />
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="6">
              <v-text-field
                :value="jobDataLocal.minSalary"
                outlined
                dense
                step=".01"
                type="number"
                hide-details="auto"
                max="99999999.99"
                :label="$t('minSalary')"
                :placeholder="$t('minSalary')"
                :rules="[validators.minSalaryValue, validators.maxSalaryValue]"
                @change="setMinSalary($event)"
              ></v-text-field>
            </v-col>
            <v-col v-if="$can(ACTIONS.JOBS.MANAGE_JOB, props.jobData)" cols="6">
              <v-text-field
                :value="jobDataLocal.maxSalary"
                outlined
                dense
                step=".01"
                max="99999999.99"
                type="number"
                hide-details="auto"
                :label="$t('maxSalary')"
                :placeholder="$t('maxSalary')"
                :rules="[validators.minSalaryValue, validators.maxSalaryValue]"
                @change="setMaxSalary($event)"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed, getCurrentInstance, nextTick } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { minSalaryValue, maxSalaryValue, required } from '@core/utils/validation'
import _ from 'lodash'
import { invoke, until } from '@vueuse/core'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'
import { EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE } from '@/plugins/summer-note/variables'
import { ACTIONS } from '@/plugins/acl/const'

export default {
  components: {
    SummerNote,
  },
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    jobData: {
      type: Object,
      required: true,
    },
    recruiterChoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { updatedJobPart, getEmailTemplatesChoices, getRejectReasonsChoices, rejectAllCandidates } = useActions(
      'jobs',
      ['updatedJobPart', 'getEmailTemplatesChoices', 'getRejectReasonsChoices', 'rejectAllCandidates'],
    )

    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const jobDataLocal = ref({})
    jobDataLocal.value = _.cloneDeep({
      stage: props.jobData.stage,
      title: props.jobData.title,
      description: props.jobData.description,
      assignedTo: props.jobData.assignedTo,
      city: props.jobData.city,
      country: props.jobData.country,
      minSalary: props.jobData.minSalary,
      maxSalary: props.jobData.maxSalary,
      sharedWith: props.jobData.sharedWith?.map((rec) => rec.id),
    })

    // ----------- Email part -----------
    const sendEmail = ref(true)
    const emailTemplates = ref([])
    const selectedEmail = ref(null)
    const selectedEmailTemplateHtml = ref('')
    const selectedEmailTemplateSubject = ref('')
    const selectedEmailTemplateLang = ref('')
    const cc = ref('')
    const sendCopyToRecruiter = ref(false)
    const rejectReasonChoices = ref([])
    const selectedRejectReason = ref(null)

    const vm = getCurrentInstance().proxy

    const editor = ref(null)
    const emailVariablesSummernote = ref([])
    emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)
    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', selectedEmailTemplateHtml.value)
      editor.value.$on('change', (content) => {
        selectedEmailTemplateHtml.value = content
      })
    }

    const setEmailTemplateData = () => {
      const emailTemplate = emailTemplates.value.find((et) => et.value === selectedEmail.value)
      selectedEmailTemplateHtml.value = emailTemplate?.bodyHtml
      selectedEmailTemplateSubject.value = emailTemplate?.subject
      selectedEmailTemplateLang.value = emailTemplate?.lang
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
    }

    const resetEmailTemplateData = () => {
      sendEmail.value = true
      selectedEmail.value = null
      selectedEmailTemplateHtml.value = ''
      selectedEmailTemplateSubject.value = ''
      selectedEmailTemplateLang.value = ''
      cc.value = ''
      sendCopyToRecruiter.value = false
      selectedRejectReason.value = null
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
    }

    invoke(async () => {
      await until(
        computed(() => props.jobData.stage !== jobDataLocal.value.stage && jobDataLocal.value.stage === 'finished'),
      ).toBe(true)
      nextTick(async () => {
        rejectReasonChoices.value = await getRejectReasonsChoices()
        emailTemplates.value = await getEmailTemplatesChoices({
          actionType: 'rejected',
        })
      })
    })

    watch(
      () => jobDataLocal.value.stage,
      (newVal, oldVal) => {
        if (newVal === 'finished' && oldVal === 'ongoing') resetEmailTemplateData()
      },
    )

    // ----------------------

    const setMinSalary = (value) => {
      jobDataLocal.value.minSalary = value
      nextTick(() => {
        jobDataLocal.value.minSalary = _.floor(value, 2)
      })
    }
    const setMaxSalary = (value) => {
      jobDataLocal.value.maxSalary = value
      nextTick(() => {
        jobDataLocal.value.maxSalary = _.floor(value, 2)
      })
    }

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      let successfullyUpdated

      if (props.jobData.stage !== jobDataLocal.value.stage && jobDataLocal.value.stage === 'finished') {
        const { success } = await rejectAllCandidates({
          jobId: props.jobData.id,
          bodyHtml: selectedEmailTemplateHtml.value,
          subject: selectedEmailTemplateSubject.value,
          lang: selectedEmailTemplateLang.value,
          cc: cc.value,
          sendCopyToRecruiter: sendCopyToRecruiter.value,
          rejectReason: selectedRejectReason.value,
          sendEmail: sendEmail.value,
        })
        const { success: jobUpdated } = await updatedJobPart({
          jobId: props.jobData.id,
          payload: {
            ...jobDataLocal.value,

            // sharedWith: props.recruiterChoices.filter((user) => !jobDataLocal.value.sharedWith.includes(user)),
            sharedWith: jobDataLocal.value.sharedWith.map((rec) => {
              return {
                id: rec,
              }
            }),
          },
        })
        successfullyUpdated = success && jobUpdated

        if (successfullyUpdated) emit('refetch-candidates')
      } else {
        const { success } = await updatedJobPart({
          jobId: props.jobData.id,
          payload: {
            ...jobDataLocal.value,

            // sharedWith: props.recruiterChoices.filter((user) => !jobDataLocal.value.sharedWith.includes(user)),
            sharedWith: jobDataLocal.value.sharedWith.map((rec) => {
              return {
                id: rec,
              }
            }),
          },
        })
        successfullyUpdated = success
      }

      loading.value = false

      if (successfullyUpdated) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    const areDataChanged = computed(() => {
      return (
        jobDataLocal.value.stage !== props.jobData.stage ||
        jobDataLocal.value.title !== props.jobData.title ||
        jobDataLocal.value.description !== props.jobData.description ||
        jobDataLocal.value.assignedTo !== props.jobData.assignedTo ||
        jobDataLocal.value.city !== props.jobData.city ||
        jobDataLocal.value.country !== props.jobData.country ||
        jobDataLocal.value.minSalary !== props.jobData.minSalary ||
        jobDataLocal.value.maxSalary !== props.jobData.maxSalary ||
        !_.isEmpty(_.differenceWith(jobDataLocal.value.sharedWith, props.jobData.sharedWith, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(props.jobData.sharedWith, jobDataLocal.value.sharedWith, _.isEqual))
      )
    })

    const getStageItems = computed(() => {
      if (props.jobData.stage === 'draft') {
        return [
          {
            text: 'Draft',
            value: 'draft',
          },
          {
            text: 'Ongoing',
            value: 'ongoing',
          },
        ]
      }
      if (props.jobData.stage === 'ongoing') {
        return [
          {
            text: 'Ongoing',
            value: 'ongoing',
          },
          {
            text: 'Finished',
            value: 'finished',
          },
        ]
      }
      if (props.jobData.stage === 'finished') {
        return [
          {
            text: 'Finished',
            value: 'finished',
          },
          {
            text: 'Archived',
            value: 'archived',
          },
        ]
      }

      return [
        {
          text: 'Archived',
          value: 'archived',
        },
      ]
    })

    const assignedToItems = ref([])
    const sharedWithItems = ref([])

    const reArrangeAssignedToItems = () => {
      const assignedToAdmins = [{ header: 'Admin' }]
      const assignedToRecruiters = [{ divider: true }, { header: 'Recruiter' }]
      assignedToItems.value = props.recruiterChoices.filter((user) => !['expert', 'suspended'].includes(user.access))

      assignedToItems.value.forEach((user) => {
        if (user.access === 'admin') {
          assignedToAdmins.push({
            text: `${user.user.firstName} ${user.user.lastName}`,
            value: user.id,
          })
        } else if (user.access === 'recruiter') {
          assignedToRecruiters.push({
            text: `${user.user.firstName} ${user.user.lastName}`,
            value: user.id,
          })
        }
      })
      assignedToItems.value = [...assignedToAdmins, ...assignedToRecruiters]
    }

    const reArrangeSharedWithItems = () => {
      const sharedWithAdmins = [{ header: 'Admin' }]
      const sharedWithRecruiters = [{ divider: true }, { header: 'Recruiter' }]
      const sharedWithExperts = [{ divider: true }, { header: 'Expert' }]

      sharedWithItems.value = props.recruiterChoices.filter(
        (user) => !['suspended'].includes(user.access) && user.id !== jobDataLocal.value.assignedTo,
      )

      sharedWithItems.value.forEach((user) => {
        if (user.access === 'admin') {
          sharedWithAdmins.push({
            text: `${user.user.firstName} ${user.user.lastName}`,
            value: user.id,
          })
        } else if (user.access === 'recruiter') {
          sharedWithRecruiters.push({
            text: `${user.user.firstName} ${user.user.lastName}`,
            value: user.id,
          })
        } else if (user.access === 'expert') {
          sharedWithExperts.push({
            text: `${user.user.firstName} ${user.user.lastName}`,
            value: user.id,
          })
        }
      })
      sharedWithItems.value = [...sharedWithAdmins, ...sharedWithRecruiters, ...sharedWithExperts]

      jobDataLocal.value.sharedWith = jobDataLocal.value.sharedWith?.filter(
        (user) => user !== jobDataLocal.value.assignedTo,
      )
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        reArrangeAssignedToItems()
        reArrangeSharedWithItems()

        jobDataLocal.value = _.cloneDeep({
          stage: props.jobData.stage,
          title: props.jobData.title,
          description: props.jobData.description,
          assignedTo: props.jobData.assignedTo,
          city: props.jobData.city,
          country: props.jobData.country,
          minSalary: props.jobData.minSalary,
          maxSalary: props.jobData.maxSalary,
          sharedWith: props.jobData.sharedWith?.map((rec) => rec.id),
        })
      },
    )

    return {
      sendEmail,
      emailTemplates,
      selectedEmail,
      selectedEmailTemplateHtml,
      selectedEmailTemplateSubject,
      selectedEmailTemplateLang,
      cc,
      sendCopyToRecruiter,
      rejectReasonChoices,
      selectedRejectReason,
      readySummerNote,
      setEmailTemplateData,

      props,

      setMinSalary,
      setMaxSalary,
      handleFormSubmit,
      reArrangeSharedWithItems,

      areDataChanged,
      getStageItems,

      valid,
      loading,
      bioEditForm,
      jobDataLocal,
      assignedToItems,
      sharedWithItems,

      validators: {
        required,
        minSalaryValue,
        maxSalaryValue,
      },
      ACTIONS,
    }
  },
}
</script>
